import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import moment from 'moment';

// Context
import ContextConsumer from '../../../contextProvider';

const Wrapper = styled(Link)`
  position: relative;
  height: 560px;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: ${(props) =>
      props.theme.responsive.medium}) {
    height: 424px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.4)
    );
    transition: all 0.9s ease;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: #000;
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }
  .gatsby-image-wrapper {
    height: 100%;
    img {
      transform: scale(1);
      transition: all 1s cubic-bezier(0.59, 0, 0.06, 1) 0s !important;
    }
  }
  &:hover {
    &::after {
      opacity: 0.6;
    }
    .gatsby-image-wrapper {
      img {
        transform: scale(1.1);
      }
    }
  }
`;

const Text = styled.div`
  position: absolute;
  bottom: 30px;
  padding: 10px;
  width: 100%;
  text-align: center;
  z-index: 99;
  h2 {
    font-size: 32px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.text.light};
    text-align: center;
    margin-bottom: 5px;
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.medium}) {
      font-size: 26px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: ${(props) => props.theme.colors.text.light};
    padding-left: 0;
    margin-bottom: 15px;
    list-style-type: disc;
    li {
      margin-left: 30px;
      display: list-item;
      text-align: -webkit-match-parent;
      &:first-child {
        list-style: none;
        margin-left: 0;
      }
      @media only screen and (max-width: ${(props) =>
          props.theme.responsive.xsmall}) {
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          list-style: none;
          margin-left: 0;
        }
      }
    }
  }
  span {
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary} !important;
  }
`;

const Item = ({ itemData }) => (
  <ContextConsumer>
    {({ location }) => (
      <>
        {itemData.map(({ node: item }) => {
          let preSlug;
          if (location.pathname.includes('rooms')) {
            preSlug = '/rooms';
          } else if (location.pathname.includes('apartments')) {
            preSlug = '/apartments';
          } else if (location.pathname.includes('specials')) {
            preSlug = '/specials';
          } else if (location.pathname.includes('events')) {
            preSlug = '/events';
          } else {
            preSlug = null;
          }

          let listItems;
          if (location.pathname.includes('rooms')) {
            listItems = (
              <ul>
                {item.features.slice(0, 2).map((i) => (
                  <li key={i.id}>{i.title}</li>
                ))}
              </ul>
            );
          } else if (location.pathname.includes('apartments')) {
            listItems = (
              <ul>
                {item.features.slice(0, 2).map((i) => (
                  <li key={i.id}>{i.title}</li>
                ))}
              </ul>
            );
          } else if (location.pathname.includes('specials')) {
            listItems = (
              <ul>
                <li>{item.rates}</li>
              </ul>
            );
          } else if (location.pathname.includes('events')) {
            listItems = (
              <ul>
                <li>
                  {moment(item.fields.startDate).format('MMMM Do YYYY')} at{' '}
                  {moment(item.fields.startDate).format('h:mm a')}
                </li>
              </ul>
            );
          } else {
            listItems = null;
          }

          let linkTo;
          if (location.pathname.includes('rooms')) {
            linkTo = item.seoContent.slug;
          } else if (location.pathname.includes('apartments')) {
            linkTo = item.seoContent.slug;
          } else if (location.pathname.includes('specials')) {
            linkTo = item.seoContent.slug;
          } else if (location.pathname.includes('events')) {
            linkTo = item.fields.seoContent.slug + `/` + item.fields.id;
          } else {
            linkTo = null;
          }

          let linkTitle;
          if (location.pathname.includes('rooms')) {
            linkTitle = item.seoContent.pageTitle;
          } else if (location.pathname.includes('apartments')) {
            linkTitle = item.seoContent.pageTitle;
          } else if (location.pathname.includes('specials')) {
            linkTitle = item.seoContent.pageTitle;
          } else if (location.pathname.includes('events')) {
            linkTitle = item.fields.seoContent.pageTitle;
          } else {
            linkTitle = null;
          }

          let titleMain;
          if (location.pathname.includes('rooms')) {
            titleMain = item.seoContent.menuTitle;
          } else if (location.pathname.includes('apartments')) {
            titleMain = item.seoContent.menuTitle;
          } else if (location.pathname.includes('specials')) {
            titleMain = item.seoContent.menuTitle;
          } else if (location.pathname.includes('events')) {
            titleMain = item.fields.seoContent.menuTitle;
          } else {
            titleMain = null;
          }

          let itemImage;
          if (location.pathname.includes('rooms')) {
            itemImage = item.seoContent.featuredImage.fluid;
          } else if (location.pathname.includes('apartments')) {
            itemImage = item.seoContent.featuredImage.fluid;
          } else if (location.pathname.includes('specials')) {
            itemImage = item.seoContent.featuredImage.fluid;
          } else if (location.pathname.includes('events')) {
            itemImage = item.childImageSharp.fluid;
          } else {
            listItems = null;
          }

          return (
            <Wrapper
              key={item.id}
              to={`${preSlug}/${linkTo}/`}
              title={linkTitle}
            >
              <Img fluid={itemImage} alt={linkTitle} />
              <Text>
                <h2>{titleMain}</h2>
                {listItems}
                <span>MORE DETAILS</span>
              </Text>
            </Wrapper>
          );
        })}
      </>
    )}
  </ContextConsumer>
);

export default Item;
