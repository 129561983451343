import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 10vw 32px;
  @media (min-width: ${props => props.theme.responsive.small}) {
    padding: 6vw 30px;
    margin: 0 auto;
  }
  h1 {
    color: ${props => props.theme.colors.primary};
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    padding-bottom: 10px;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      padding-bottom: 15px;
      font-size: 28px;
      line-height: 35px;
    }
  }
  h2 {
    color: ${props => props.theme.colors.text.normal};
    position: relative;
    font-size: 22px;
    line-height: 34px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    text-transform: uppercase;
    text-align: center;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      font-size: 18px;
      line-height: 26px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 270px;
      max-width: 50%;
      height: 1px;
      background: ${props => props.theme.colors.misc.border};
    }
  }
  p {
    font-size: 16px;
    text-align: center;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      max-width: 90%;
      font-size: 20px;
      margin: 0 auto;
    }
  }
`;

export default Wrapper;
