import styled from 'styled-components';

const Wrapper = styled.section`
  max-width: 1160px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    grid-template-columns: 1fr;
  }
  &.last-section {
    margin-bottom: 80px;
  }
`;

export default Wrapper;
