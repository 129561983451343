import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import Introduction from '../components/common/sections/introduction';
import ItemGrid from '../components/common/sections/itemGrid';

const SpecialsPage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;
  const specialData = data.allContentfulSpecials.edges;

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <Introduction contentSection={contentSections[0]} />
      <ItemGrid isLast itemData={specialData} />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "68e04070-ea58-5238-b00d-2614a7741842" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        title
        subtitle
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulSpecials {
      edges {
        node {
          id
          seoContent {
            featuredImage {
              title
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            menuTitle
            pageTitle
            slug
          }
          rates
        }
      }
    }
  }
`;

export default SpecialsPage;
