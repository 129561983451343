import React from 'react';

// Components
import { Wrapper } from './files';

const Introduction = ({ contentSection }) => {
  const { title, subtitle, text } = contentSection;
  return (
    <Wrapper>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: text.childMarkdownRemark.html
        }}
      />
    </Wrapper>
  );
};

export default Introduction;
