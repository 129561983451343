import React from 'react';

// Components
import { Wrapper, Item } from './files';

const ItemGrid = ({ itemData, isLast }) => (
  <Wrapper className={isLast ? 'last-section' : ''}>
    <Item itemData={itemData} />
  </Wrapper>
);

export default ItemGrid;
